import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";

// SETTING HOOK
export const Table = () => {
  const [data, handleData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  // FETCHING DATA TO LOGS
  async function fetchData() {
    const data = await fetch("https://logsapi.grm.digital/api/logs", {
      headers: {
        Authorization:
          "McQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s5v8y/B?E(H+MbQeShVmYq3t6w9z$C&F)J@NcRfUjWnZr4u7x!A%D*G-KaPdSgVkYp2s5v8y/B?E(H+MbQeThWmZq4t6w9z$C&F)J@NcRfUjXn2r5u8x!A%D*G-KaPdSgVkYp3s6v9y$B?E(H+MbQeThWmZq4t7w!z%C*F)J@NcRfUjXn2r5u8x/A?D(G+KaPdSgVkYp3s6v9y$B&E)H@McQeThWmZq4t7w!z%C*F-JaNdRgUjXn2r5u8x/A?D(G+KbPeShVmYp3s6v9y$B&E)H@McQfTjWnZr4t7w!z%C*F-JaNdRgUkXp2s5v8x/A?D(G+KbPeShVmYq3t6w9z$C&E)H@McQfTjWnZr4u7x!A%D*G-JaNdRgUkXp2s5v8y/B?E(H+MbPeShVmYq3t6w9z$C&F)J@NcRfTjWnZr4u7x!A%D*G-KaPdSgVkXp2s5v8y/B?E(H+MbQeThWmZq3t6w9z$C&F)J",
      },
    });
    const data2 = await data.json();
    //  data2 = JSON.parse(data2);
    handleData(data2);
  }
  //COLUMNS added
  const colums = [
    {
      title: "Host",
      field: "host",
    },
    {
      title: "Referrer",
      field: "referer",
    },
    {
      title: "From URL",
      field: "fromUrl",
    },
    {
      title: "To URL",
      field: "toUrl",
    },
    {
      title: "Status code",
      field: "status",
    },
    {
      title: "IP Address",
      field: "ip",
    },
    {
      title: "Country",
      field: "country",
    },
    {
      title: "User Agent",
      field: "userAgent",
    },
    {
      title: "Time and date",
      field: "timestamp",
      defaultSort: "desc",
    },
  ];
  // RENDER TABLE
  return (
    <div>
      <MaterialTable
        title="IRCO - Worker Logs"
        data={data}
        columns={colums}
        options={{
          filtering: true,
          exportButton: true,
          exportAllData: true,
          emptyRowsWhenPaging: false,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100, 200],
        }}
      />
    </div>
  );
};
